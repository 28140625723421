import React from "react"
import Seo from "../components/molecules/Seo"
import styled from "styled-components";
import {Link} from "gatsby";

const Holder = styled.div`
  margin: 15rem 1rem 0 1rem;
  color: #FE4F32;

  .button {
    color: #000F1D;
  }
`;

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Holder>
      <h1>404, not found</h1>
      <p>You hit a page that doesn't exist</p>
      <p><Link className="button" to={'/'}>Back to home</Link></p>
    </Holder>
  </>
)

export default NotFoundPage
